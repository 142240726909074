import React, { useState } from "react"
import { Link } from 'gatsby'
import { Transition } from './helpers/transition'
import logo from '../images/logo/logo-500x500.svg'

type Props = {
  siteTitle?: string
}
export const Header = ({ siteTitle = '' }: Props) => {
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false)
  const [flyoutIsOpen, setFlyoutIsOpen] = useState<boolean>(false)
  const [readMoreFlyoutIsOpen, setReadMoreFlyoutIsOpen] = useState<boolean>(false)

  const toggleMainFlyout = () => {
    setFlyoutIsOpen(!flyoutIsOpen)
    if (readMoreFlyoutIsOpen) {
      setReadMoreFlyoutIsOpen(false)
    }
  }
  const toggleSecondaryFlyout = () => {
    setReadMoreFlyoutIsOpen(!readMoreFlyoutIsOpen)
    if (flyoutIsOpen) {
      setFlyoutIsOpen(false)
    }
  }
  return (
    <header>
      <div className="relative bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-center border-b-2 border-warm-grey-100 py-6 md:justify-start md:space-x-10">
            <div className="lg:w-0 lg:flex-1">
              <Link to="/" className="flex">
                <img className="h-10 w-auto sm:h-12" src={logo} alt="Brandbilen" />
              </Link>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <button onClick={() => setMenuIsOpen(!menuIsOpen)} type="button" className="inline-flex items-center justify-center p-2 rounded-md text-warm-grey-400 hover:text-warm-grey-500 hover:bg-warm-grey-100 focus:outline-none focus:bg-warm-grey-100 focus:text-warm-grey-500 transition duration-150 ease-in-out">
                <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              </button>
            </div>
            <nav className="hidden md:flex space-x-10">
              <div className="relative">
                <button onClick={() => toggleMainFlyout()} type="button" className={`${flyoutIsOpen ? 'text-warm-grey-900' : 'text-warm-grey-500'} group inline-flex items-center space-x-2 text-base leading-6 font-medium hover:text-warm-grey-900 focus:outline-none focus:text-warm-grey-900 transition ease-in-out duration-150`}>
                  <span>Brandbilen</span>
                  <svg className={`${flyoutIsOpen ? 'text-warm-grey-600' : 'text-warm-grey-400'} h-5 w-5 group-hover:text-warm-grey-500 group-focus:text-warm-grey-500 transition ease-in-out duration-150`} fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </button>
                <Transition
                  show={flyoutIsOpen}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <div className="z-20 absolute -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                    <div className="rounded-lg shadow-lg">
                      <div className="rounded-lg shadow-xs overflow-hidden">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          <Link to="/om-brandbilen" className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-warm-grey-50 transition ease-in-out duration-150">
                            <svg className="flex-shrink-0 h-6 w-6 text-bb-red-600" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122" />
                            </svg>
                            <div className="space-y-1">
                              <p className="text-base leading-6 font-medium text-warm-grey-900">
                                Om Brandbilen
                              </p>
                              <p className="text-sm leading-5 text-warm-grey-500">
                                Läs mer om vår historia.
                              </p>
                            </div>
                          </Link>
                          <Link to="/teman" className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-warm-grey-50 transition ease-in-out duration-150">
                            <svg className="flex-shrink-0 h-6 w-6 text-bb-red-600" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" stroke="currentColor" viewBox="0 0 24 24">
                              <path d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z" />
                            </svg>
                            <div className="space-y-1">
                              <p className="text-base leading-6 font-medium text-warm-grey-900">
                                Teman
                              </p>
                              <p className="text-sm leading-5 text-warm-grey-500">
                                Förstå mer om de teman som jobbas med på förskolan.
                              </p>
                            </div>
                          </Link>
                          <Link to="/personal" className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-warm-grey-50 transition ease-in-out duration-150">
                            <svg className="flex-shrink-0 h-6 w-6 text-bb-red-600" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" stroke="currentColor" viewBox="0 0 24 24">
                              <path d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                            </svg>
                            <div className="space-y-1">
                              <p className="text-base leading-6 font-medium text-warm-grey-900">
                                Personal
                              </p>
                              <p className="text-sm leading-5 text-warm-grey-500">
                                Lär känna våra fantastiska medarbetare.
                              </p>
                            </div>
                          </Link>
                          <Link to="/jobba-pa-brandbilen" className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-warm-grey-50 transition ease-in-out duration-150">
                            <svg className="flex-shrink-0 h-6 w-6 text-bb-red-600" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" stroke="currentColor" viewBox="0 0 24 24">
                              <path d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
                            </svg>
                            <div className="space-y-1">
                              <p className="text-base leading-6 font-medium text-warm-grey-900">
                                Jobba på Brandbilen
                              </p>
                              <p className="text-sm leading-5 text-warm-grey-500">
                                Är du intresserad att söka jobb eller vara vikarie hos oss?
                              </p>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Transition>
              </div>

              <Link to="/tyra" className="text-base leading-6 font-medium text-warm-grey-500 hover:text-warm-grey-900 focus:outline-none focus:text-warm-grey-900 transition ease-in-out duration-150">
                Tyra
              </Link>
              <Link to="/en-dag-pa-brandbilen" className="text-base leading-6 font-medium text-warm-grey-500 hover:text-warm-grey-900 focus:outline-none focus:text-warm-grey-900 transition ease-in-out duration-150">
                En dag på Brandbilen
              </Link>
              <Link to="/kontakt" className="text-base leading-6 font-medium text-warm-grey-500 hover:text-warm-grey-900 focus:outline-none focus:text-warm-grey-900 transition ease-in-out duration-150">
                Kontakta oss
              </Link>

              <div className="relative">
                <button onClick={() => toggleSecondaryFlyout()} type="button" className={`${readMoreFlyoutIsOpen ? 'text-warm-grey-900' : 'text-warm-grey-500'} group inline-flex items-center space-x-2 text-base leading-6 font-medium hover:text-warm-grey-900 focus:outline-none focus:text-warm-grey-900 transition ease-in-out duration-150`}>
                  <span>Läs mer</span>
                  <svg className={`${flyoutIsOpen ? 'text-warm-grey-600' : 'text-warm-grey-400'} h-5 w-5 group-hover:text-warm-grey-500 group-focus:text-warm-grey-500 transition ease-in-out duration-150`} fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </button>
                <Transition
                  show={readMoreFlyoutIsOpen}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <div className="z-20 absolute -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                    <div className="rounded-lg shadow-lg">
                      <div className="rounded-lg shadow-xs overflow-hidden">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          <Link to="/styrelsen" className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-warm-grey-50 transition ease-in-out duration-150">
                            <svg className="flex-shrink-0 h-6 w-6 text-bb-red-600" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" stroke="currentColor" viewBox="0 0 24 24">
                              <path d="M13 10V3L4 14h7v7l9-11h-7z" />
                            </svg>
                            <div className="space-y-1">
                              <p className="text-base leading-6 font-medium text-warm-grey-900">
                                Styrelsen
                              </p>
                              <p className="text-sm leading-5 text-warm-grey-500">
                                Brandbilen ägs och drivs av föräldrar.
                              </p>
                            </div>
                          </Link>
                          <Link to="/organisation" className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-warm-grey-50 transition ease-in-out duration-150">
                            <svg className="flex-shrink-0 h-6 w-6 text-bb-red-600" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" stroke="currentColor" viewBox="0 0 24 24">
                              <path d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                            </svg>
                            <div className="space-y-1">
                              <p className="text-base leading-6 font-medium text-warm-grey-900">
                                Organisation
                              </p>
                              <p className="text-sm leading-5 text-warm-grey-500">
                                Lär dig mer om hur Brandbilens verksamhet fungerar.
                              </p>
                            </div>
                          </Link>
                          <Link to="/foraldrainsats" className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-warm-grey-50 transition ease-in-out duration-150">
                            <svg className="flex-shrink-0 h-6 w-6 text-bb-red-600" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" stroke="currentColor" viewBox="0 0 24 24">
                              <path d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />
                            </svg>
                            <div className="space-y-1">
                              <p className="text-base leading-6 font-medium text-warm-grey-900">
                                Föräldrainsats
                              </p>
                              <p className="text-sm leading-5 text-warm-grey-500">
                                Lär dig mer om vad föräldrainsatser på Brandbilen innebär
                              </p>
                            </div>
                          </Link>
                          <Link to="/synpunkter" className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-warm-grey-50 transition ease-in-out duration-150">
                            <svg className="flex-shrink-0 h-6 w-6 text-bb-red-600" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" stroke="currentColor" viewBox="0 0 24 24">
                              <path d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                            </svg>
                            <div className="space-y-1">
                              <p className="text-base leading-6 font-medium text-warm-grey-900">
                                Synpunkter/tankar om verksamheten
                              </p>
                              <p className="text-sm leading-5 text-warm-grey-500">
                                Dina synpunkter är värdefulla för att vi ska kunna förbättra verksamheten.
                              </p>
                            </div>
                          </Link>
                          <Link to="/vanliga-fragor" className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-warm-grey-50 transition ease-in-out duration-150">
                            <svg viewBox="0 0 24 24" fill="currentColor" className="flex-shrink-0 h-6 w-6 text-bb-red-600">
                              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                            </svg>
                            <div className="space-y-1">
                              <p className="text-base leading-6 font-medium text-warm-grey-900">
                                Vanliga frågor
                              </p>
                              <p className="text-sm leading-5 text-warm-grey-500">
                                Få svar på dina funderingar
                              </p>
                            </div>
                          </Link>
                          <Link to="/ovrig-information" className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-warm-grey-50 transition ease-in-out duration-150">
                            <svg className="flex-shrink-0 h-6 w-6 text-bb-red-600" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" stroke="currentColor" viewBox="0 0 24 24">
                              <path d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <div className="space-y-1">
                              <p className="text-base leading-6 font-medium text-warm-grey-900">
                                Övrig information
                              </p>
                              <p className="text-sm leading-5 text-warm-grey-500">
                                Men fortfarande viktig information
                              </p>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Transition>
              </div>
            </nav>
            <div className="hidden md:flex items-center justify-end space-x-8 md:flex-1 lg:w-0">
              <span className="inline-flex rounded-md shadow-sm">
                <Link to="/intresseanmalan" className="whitespace-no-wrap inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-bb-red-600 hover:bg-bb-red-500 focus:outline-none focus:border-bb-red-700 focus:shadow-outline-bb-red active:bg-bb-red-700 transition ease-in-out duration-150">
                  Intresseanmälan
                </Link>
              </span>
            </div>
          </div>
        </div>

        <Transition
          show={menuIsOpen}
          enter="transition duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="z-20 absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
            <div className="rounded-lg shadow-lg">
              <div className="rounded-lg shadow-xs bg-white divide-y-2 divide-gray-50">
                <div className="pt-5 pb-6 px-5 space-y-6">
                  <div className="flex items-center justify-between">
                    <div>
                      <img className="h-8 w-auto" src={logo} alt="Brandbilen" />
                    </div>
                    <div className="-mr-2">
                      <button onClick={() => setMenuIsOpen(!menuIsOpen)} type="button" className="inline-flex items-center justify-center p-2 rounded-md text-warm-grey-400 hover:text-warm-grey-500 hover:bg-warm-grey-100 focus:outline-none focus:bg-warm-grey-100 focus:text-warm-grey-500 transition duration-150 ease-in-out">
                        <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div>
                    <nav className="grid row-gap-8">
                      <Link to="/om-brandbilen" className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-warm-grey-50 transition ease-in-out duration-150">
                        <svg className="flex-shrink-0 h-6 w-6 text-bb-red-600" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122" />
                        </svg>
                        <div className="text-base leading-6 font-medium text-warm-grey-900">
                          Om Brandbilen
                        </div>
                      </Link>
                      <Link to="/teman" className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-warm-grey-50 transition ease-in-out duration-150">
                        <svg className="flex-shrink-0 h-6 w-6 text-bb-red-600" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" stroke="currentColor" viewBox="0 0 24 24">
                          <path d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z" />
                        </svg>
                        <div className="text-base leading-6 font-medium text-warm-grey-900">
                          Teman
                        </div>
                      </Link>
                      <Link to="/personal" className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-warm-grey-50 transition ease-in-out duration-150">
                        <svg className="flex-shrink-0 h-6 w-6 text-bb-red-600" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" stroke="currentColor" viewBox="0 0 24 24">
                          <path d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                        </svg>
                        <div className="text-base leading-6 font-medium text-warm-grey-900">
                          Personal
                        </div>
                      </Link>
                      <Link to="/jobba-pa-brandbilen" className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-warm-grey-50 transition ease-in-out duration-150">
                        <svg className="flex-shrink-0 h-6 w-6 text-bb-red-600" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" stroke="currentColor" viewBox="0 0 24 24">
                          <path d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
                        </svg>
                        <div className="text-base leading-6 font-medium text-warm-grey-900">
                          Jobba på Brandbilen
                        </div>
                      </Link>
                    </nav>
                  </div>
                </div>
                <div className="py-6 px-5 space-y-6">
                  <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                    <Link to="/tyra" className="text-base leading-6 font-medium text-warm-grey-900 hover:text-warm-grey-700 transition ease-in-out duration-150">
                      Tyra
                    </Link>
                    <Link to="/en-dag-pa-brandbilen" className="text-base leading-6 font-medium text-warm-grey-900 hover:text-warm-grey-700 transition ease-in-out duration-150">
                      En dag på Brandbilen
                    </Link>
                    <Link to="/kontakt" className="text-base leading-6 font-medium text-warm-grey-900 hover:text-warm-grey-700 transition ease-in-out duration-150">
                      Kontakta oss
                    </Link>
                    <Link to="/styrelsen" className="text-base leading-6 font-medium text-warm-grey-900 hover:text-warm-grey-700 transition ease-in-out duration-150">
                      Styrelsen
                    </Link>
                    <Link to="/organisation" className="text-base leading-6 font-medium text-warm-grey-900 hover:text-warm-grey-700 transition ease-in-out duration-150">
                      Organisation
                    </Link>
                    <Link to="/foraldrainsats" className="text-base leading-6 font-medium text-warm-grey-900 hover:text-warm-grey-700 transition ease-in-out duration-150">
                      Föräldrainsats
                    </Link>
                    <Link to="/vanliga-fragor" className="text-base leading-6 font-medium text-warm-grey-900 hover:text-warm-grey-700 transition ease-in-out duration-150">
                      Vanliga frågor
                    </Link>
                    <Link to="/synpunkter" className="text-base leading-6 font-medium text-warm-grey-900 hover:text-warm-grey-700 transition ease-in-out duration-150">
                      Synpunkter
                    </Link>
                    <Link to="/ovrig-information" className="text-base leading-6 font-medium text-warm-grey-900 hover:text-warm-grey-700 transition ease-in-out duration-150">
                      Övrig information
                    </Link>
                  </div>
                  <div className="space-y-6">
                    <span className="w-full flex rounded-md shadow-sm">
                      <Link to="/intresseanmalan" className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-bb-red-600 hover:bg-bb-red-500 focus:outline-none focus:border-bb-red-700 focus:shadow-outline-bb-red active:bg-bb-red-700 transition ease-in-out duration-150">
                        Intresseanmälan
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </header>
  )
}

export default Header
