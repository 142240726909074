import React, { FC } from 'react'

export const ContentContainer: FC = ({ children }) => {
  return (
    <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 sm:py-6 lg:px-8 lg:py-8">
      <div className="max-w-3xl mx-auto">
        {children}
      </div>
    </div>
  )
}