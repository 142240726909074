import React from "react"
import uniq from 'lodash/uniq'
import map from 'lodash/map'
import get from 'lodash/get'
import capitalize from 'lodash/capitalize'
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

type Props = {
  title: string
  description?: string
  pathname?: string
  lang?: string
  meta?: object[]
  image?: {
    src: string
    width: number
    height: number
  }
  keywords?: string[]
}

export function SEO({ description = '', lang = 'sv', meta = [], title, image, pathname, keywords = [] }: Props) {
  const { site, seoThumbnail } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            thumbnail
            keywords
          }
        }
        seoThumbnail: file(relativePath: { eq: "thumbnails/og.png" }) {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaImage = image && image.src ? image : get(seoThumbnail, 'childImageSharp.resize')
  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null

  const defaultKeywords = site.siteMetadata && site.siteMetadata.keywords ? site.siteMetadata.keywords.split(',') : []
  const jointKeywords = map(uniq([...keywords, ...defaultKeywords]), key => capitalize(key))

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={
        canonical
          ? [
            {
              rel: "canonical",
              href: canonical,
            },
          ]
          : []
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: "keywords",
          content: jointKeywords.join(","),
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(
        metaImage
          ? [
            {
              property: "og:image",
              content: metaImage.src,
            },
            {
              property: "og:image:width",
              content: metaImage.width,
            },
            {
              property: "og:image:height",
              content: metaImage.height,
            },
            {
              name: "twitter:card",
              content: "summary_large_image",
            },
          ]
          : [
            {
              name: "twitter:card",
              content: "summary",
            },
          ]
      ).concat(meta)}
    />
  )
}

export default SEO
