import React, { FC } from 'react'

type Props = {
  href: string
  target?: string
}
export const ExternalLink: FC<Props> = ({ children, href, target = '_blank' }) => {
  return (
    <a className="text-base leading-6 font-semibold text-bb-red-600 hover:text-bb-red-500 transition ease-in-out duration-150" href={href} target={target}>
      {children}
    </a>
  )
}