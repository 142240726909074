import React, { FC } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Header } from "./header"
import { Footer } from "./footer"
import { AnnouncementBanner } from "./ds"
import "./layout.css"

export const Layout: FC = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="relative bg-white overflow-hidden">
      <AnnouncementBanner />
      <Header siteTitle={data.site.siteMetadata.title} />
      <div className="relative">
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  )
}

export default Layout
