import React, { FC } from 'react'
import { Link } from 'gatsby'

type Props = {
  to: string
}
export const InternalLink: FC<Props> = ({ children, to }) => {
  return (
    <Link to={to} className="text-base leading-6 font-semibold text-bb-red-600 hover:text-bb-red-500 transition ease-in-out duration-150">
      {children}
    </Link>
  )
}